import { post } from '@/utils/request'

// 机构列表
export function businessList(data) {
    return post('/admin/business/businessList', data)
}

// 审核详情
export function businessInfo(data) {
    return post('/admin/business/businessInfo', data)
}


// 机构重新启用
export function businessPassStart(data) {
    return post('/admin/business/businessPassStart', data)
}