<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>机构管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <!-- <el-button type="primary" @click="pAdd()">添加机构</el-button> -->
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
            default-expand-all
          >
            <el-table-column type="expand">
              <template v-slot="scope">
                <!-- <el-tag
                  style="margin-right:5px"
                  v-for="item in scope.row.list"
                  :key="item.id"
                  >{{ item.key_name }}</el-tag
                > -->
                <el-table class="inner-table" border :data="scope.row.list">
                  <el-table-column
                    prop="user_name"
                    label="用户名"
                  ></el-table-column>
                  <el-table-column
                    prop="total_consumption_amount"
                    label="消费总额"
                  ></el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="business_name" label="机构名称">
            </el-table-column>

            <el-table-column prop="create_time" label="创建时间">
            </el-table-column>
            <el-table-column prop="total_consumption_amount" label="消费总额">
            </el-table-column>
            <!-- <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column> -->
            <el-table-column prop="status" label="机构状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 1" type="success"
                  >已通过</el-tag
                >
                <el-tag v-if="scope.row.status === -1" type="danger"
                  >已拒绝</el-tag
                >
                <el-tag v-if="scope.row.status === 0" type="warning"
                  >待审核</el-tag
                >
                <el-tag v-if="scope.row.status === 2" type="info"
                  >已注销</el-tag
                >
                <el-tag v-if="scope.row.status === 3">申请启用</el-tag>
              </template>
            </el-table-column>
            <el-table-column width="220" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status === 3"
                  size="mini"
                  type="success"
                  icon="el-icon-edit"
                  @click="pass(scope.row)"
                  >同意启用</el-button
                >

                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 详情-->
    <el-dialog
      title="机构详情"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-descriptions>
        <el-descriptions-item label="机构名称">{{
          approvalInfo.business_name
        }}</el-descriptions-item>

        <el-descriptions-item label="消费总额">{{
          approvalInfo.total_consumption_amount
        }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{
          approvalInfo.create_time
        }}</el-descriptions-item>
      </el-descriptions>
      <el-table class="inner-table" border :data="approvalInfo.list">
        <el-table-column prop="user_name" label="用户名"></el-table-column>
        <el-table-column
          prop="total_consumption_amount"
          label="消费总额"
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import {
  businessInfo,
  businessList,
  businessPassStart,
} from '../../../api/member/institutionmanage'
export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      imgList: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      shopList: [],
      btnLoading: false,

      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },

      dialogVisible: false,
      changeDialogVisible: false,

      formLabelWidth: '160px',

      form: {
        name: '',
        status: '',
        level: '',
        standard: '',
        start_standard: '',
      },
      tableData: [],
      approvalInfo: {},
      addDialogVisible: false,
    }
  },
  created() {
    this.getList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    pass(row) {
      this.$confirm('确认同意重新启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(async () => {
          let formData = new FormData()
          formData.append('account_id', row.account_id)
          const res = await businessPassStart(formData)
          if (res.data.code === 1) {
            this.$message.success('已通过!')

            this.getList()
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消通过',
          })
        })
    },
    handleClose(row) {
      this.addDialogVisible = false
      this.approvalInfo = {}
    },
    // 打开详情
    async openInfo(row) {
      this.addDialogVisible = true
      let formData = new FormData()
      formData.append('account_id', row.account_id)
      const res = await businessInfo(formData)
      console.log(res)
      this.approvalInfo = res.data.data
      this.row = row
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await businessList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
